






















































import Vue from 'vue';
import QRButton from './QRButton.vue';
import ModalQRCode from '@/components/Mobile/ModalQRCode.vue';

export default Vue.extend({
  components: { QRButton, ModalQRCode },
  data() {
    return {
      qrText: null as string | null,
    };
  },
  props: {
    links: {
      type: Array,
      required: true,
    },
  },
  methods: {
    openQR(text: string) {
      this.qrText = text;
    },
    onQRClosed() {
      this.qrText = null;
    },
    text(link: string) {
      if (!link.includes('http')) link = 'http://'.concat(link);
      let text = new URL(link).host;
      if (link.includes('goo.gl/maps')) {
        text = 'goo.gl/maps';
      }
      return text.replace('www.', '');
    },
    backgroundColor(link: string) {
      if (link.includes('ridee')) return '#000000';
      if (link.includes('strava')) return '#FC4C02';
      if (link.includes('komoot')) return '#6BAA26';
      if (link.includes('linked')) return '#0077B5';
      if (link.includes('facebook')) return '#4267B2';
      if (link.includes('insta')) return '#C13584';
      return '#DDDDDD';
    },
    textColor(link: string) {
      if (link.includes('ridee')) return '#FFFFFF';
      if (link.includes('strava')) return '#FFFFFF';
      if (link.includes('komoot')) return '#FFFFFF';
      if (link.includes('linked')) return '#FFFFFF';
      if (link.includes('facebook')) return '#FFFFFF';
      if (link.includes('insta')) return '#FFFFFF';
      return '#000000';
    },
  },
});
