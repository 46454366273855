//https://betterprogramming.pub/detecting-external-links-in-a-paragraph-of-text-with-javascript-automatically-3c15537f4997

const expectedDomains = [
  /\.com/,
  /\.de/,
  /\.org/,
  /\.ly/,
  /\.net/,
  /\.us/,
  /\.co/,
  /\.edu/,
  /\.gov/,
  /\.gl/,
  /\.gle/,
  /\.biz/,
  /\.za/,
  /\.info/,
  /\.team/,
  /\.berlin/,
  /\.cc/,
  /\.ca/,
  /\.cn/,
  /\.fr/,
  /\.ch/,
  /\.au/,
  /\.in/,
  /\.jp/,
  /\.be/,
  /\.it/,
  /\.nl/,
  /\.uk/,
  /\.mx/,
  /\.no/,
  /\.ru/,
  /\.br/,
  /\.se/,
  /\.es/,
  /\.at/,
  /\.dk/,
  /\.eu/,
  /\.il/,
];
function isExpectedURL(match: RegExpExecArray) {
  let domainMatch: RegExpMatchArray | null = null;
  let urlLength = 0;
  for (let i = 0; i < expectedDomains.length; i++) {
    domainMatch = match[0].match(expectedDomains[i]);
    if (domainMatch) {
      const matchIndex = domainMatch.index == undefined ? 0 : domainMatch.index;
      urlLength = domainMatch[0].length + matchIndex;
      if (match[0].length == urlLength) return true;
      if (match[0].length > urlLength && match[0].substr(urlLength, 1) == '/')
        return true;
    }
  }
  return false;
}

function isEmail(match: RegExpExecArray, text: string) {
  return match.index != 0 && text[match.index - 1] == '@';
}

function trimQuestionMark(match: RegExpExecArray, text: string) {
  if (match.index != 0 && text[match.index + match[0].length - 1] == '?') {
    match[0] = match[0].substr(0, match[0].length - 1);
  }
}

function cleanedLink(match: RegExpExecArray, text: string): string {
  let cleaned = text.substr(match.index, match[0].length);
  if (!cleaned.includes('http')) {
    cleaned = 'https://'.concat(cleaned);
  }
  return cleaned;
}

export const links = (
  text: string,
  include: string[],
  exclude: string[]
): string[] => {
  const expression =
    /(https?:\/\/)?[\w\-~]+(\.[\w\-~]+)+(\/[\w\-~@:%]*)*(#[\w-]*)?(\?[^\s]*)?/gi;
  const regex = new RegExp(expression);
  let match: RegExpExecArray | null = null;
  const links = [];
  while ((match = regex.exec(text)) != null) {
    trimQuestionMark(match, text);
    if (!isExpectedURL(match)) continue;
    if (isEmail(match, text)) continue;
    const cleaned = cleanedLink(match, text);
    const mention = (link: string) => cleaned.includes(link);
    if (
      [
        !(exclude.length + include.length),
        include.length && include.find(mention),
        exclude.length && !exclude.find(mention),
      ].some((c) => c)
    )
      links.push(cleaned);
  }

  return links;
};
