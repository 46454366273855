











import Vue from 'vue';
import QRContainer from './QRContainer.vue';
import { v4 as uuidv4 } from 'uuid';
export default Vue.extend({
  components: { QRContainer },
  props: {
    text: {
      type: String,
      default: null,
      required: false,
    },
  },
  data() {
    return {
      name: uuidv4(),
    };
  },
  watch: {
    text: function (newText) {
      if (newText) this.$modal.show(this.name);
    },
  },
});
