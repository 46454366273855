



import Vue from 'vue';
import QRCode from 'easyqrcodejs';
export default Vue.extend({
  mounted() {
    new QRCode(this.$refs.qrcode, { text: this.text }).resize(280, 280);
  },
  props: {
    text: {
      type: String,
      default: null,
      required: false,
    },
  },
});
