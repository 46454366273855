




























import Vue from 'vue';
export default Vue.extend({
  props: {
    text: {
      type: String,
      required: false,
    },
    acceptableLength: {
      type: Number,
      required: false,
      default: 150,
    },
  },
  computed: {
    displayShowMore(): boolean {
      return !this.isExpanded && this.text.length > this.acceptableLength;
    },
  },
  data() {
    return {
      isExpanded: false,
    };
  },
  methods: {
    expand() {
      this.isExpanded = true;
    },
    collapse() {
      this.isExpanded = false;
    },
  },
});
